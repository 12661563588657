
.character {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
}

.character label {
    font-size: 0.6rem;
    text-transform: uppercase;
}

.character input,
.character select {
    border-radius: 0;
    border: none;
    border-bottom: #0c1466 solid 2px;
}

.character input:focus,
.character select:focus {
    outline: none;
    border-bottom-style: dashed;
}

.character .name-class-level {
    padding: 0 16px;

    display: grid;
    grid-template-columns: 1fr auto 60px;
    grid-template-rows: 0.6rem 2rem;
    column-gap: 4px;
}

.character .ancestry-heritage {
    padding: 0 16px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.6rem 1.2rem;
    column-gap: 4px;
}

.character .background-culture-devotion {
    padding: 0 16px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 0.6rem 1.2rem;
    column-gap: 8px;
}

.character .background-culture-devotion input {
    width: 100%;
    justify-self: stretch;

    align-self: stretch;

    
}

/* @media (max-width: 1199px) {
    .character .background-culture-devotion {
        grid-template-columns: 100%;
        grid-template-rows: 0.6rem 1.2rem 0.6rem 1.2rem 0.6rem 1.2rem;
    }

    .character .background-culture-devotion input {
        margin-bottom: 4px;
    }

    .character label.background {
        grid-row: 1;
    }

    .character input.background {
        grid-row: 2;
    }

    .character label.culture {
        grid-row: 3;
    }

    .character input.culture {
        grid-row: 4;
    }

    .character label.devotion {
        grid-row: 5;
    }

    .character input.devotion {
        grid-row: 6;
    }
} */