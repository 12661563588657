
.feat {
    grid-column: 3 / 5;
    grid-row: 4 / 11;

    display: block;
}

.feat .feat-container {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    /* gap: 0.5rem; */

    /* width: 100%; */
    margin: 1rem 1rem;
}

.feat .feat-container .feat-row {
    display: flex;
    width: 100%;
    /* max-width: 750px; */
    padding: 0.25rem 0;
    border-top: 3px solid rgb(59, 69, 78);
    gap: 1rem;
}

.feat-row.future.hidden {
    display: none;
}

.feat-row-level-label {
    height: 100%;
    width: 80px;
    display: flex;
    flex-flow: column;
    gap: 1rem;
    font-weight: 600;
}

.feat-row-slots {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    width: 100%;
    max-width: 1000px;
}

.feat-slot {
    flex-basis: 300px;

    border: solid 3px #4e392ffc;
    box-shadow: 5px 5px #4e392ffc;
    max-width: 300px;
    min-width: 200px;

    background-color: white;
    padding: 4px;
    margin: 0px 5px 5px 0px;
}

.feat-slot .feat-slot-type {
    display: flex;
    justify-content: space-between;
}

.feat-slot .feat-slot-display {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.feat-slot .feat-slot-display .feat-slot-title {
    flex: 1;
    height: 1rem;
    max-width: 240px;
    border: none;
    border-bottom: solid 2px rgb(59, 69, 78);
    padding-bottom: 1rem;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.feat-slot .feat-slot-display button{
    cursor: pointer;
}

.feat-slot .feat-slot-display button:disabled {
    background-color: rgba(59, 69, 78, 0.4);
    cursor: auto;
}