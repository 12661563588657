@font-face {
    font-family: PF2E;
    src: url('../font/Pathfinder2eActions.ttf');
}

body {
    overflow: scroll;
}

h1 {
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: 1rem;
}

h2 {
    font-weight: 600;
    font-size: 1.4rem;
}

button {
    background-color: #c45500;
    color: aliceblue;
    padding: 4px;
}

#root {
    width: 100%;
    height: 100%;
}

.App {
    width: 100%;
    height: 100%;
    /* display: grid;
    grid-template-columns: 40px 50px repeat(4, 1fr) 50px 40px;
    grid-template-rows: 40px 50px repeat(8, 1fr) 50px 40px; */
}

.App .control {
    background-color: #0c1466;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.App .control button {
    padding: 4px;
    min-width: 80px;
    max-height: 35px;
}

/* CHARACTER */



/* VAULT MODAL */

.vault-characters {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 2px;
    row-gap: 2px;
}

.vault-character {
    display: flex;
    flex-flow: column nowrap;

    background-color: #522e2c;
    padding: 4px;
    color: aliceblue;
}

.vault-character-name {
    display: flex;
    justify-content: space-between;
}

.vault-character-control {
    display: flex;
    justify-content: space-between;
}

.vault-character-control button {
    background-color: #c45500;
    color: aliceblue;
}

@media (max-width: 1199px) {
    .vault-characters {
        display: flex;
        flex-flow: column nowrap;
        gap: 4px;
    }
}

.react-tooltip {
    max-width: 80vw;
}

