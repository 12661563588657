.ability-edit-modal h1 {
    font-weight: 600;
    font-size: 1.3rem;
}

.ability-rows {
    display: grid;

    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
}

.ability-row {
    margin-top: 16px;
    display: flex;
    flex-flow: column;

    justify-content: space-between;
    align-items: center;
    color: aliceblue;
    background-color: #4e392ffc;
}

.ability-row.error {
    background-color: #650d1b;
}

.ability-row-description {
    width: 80%;
    white-space: pre-line;
    font-size: 0.8rem;
}

.ability-row-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}

.ability-row-grid button {
    background-color: rgb(59, 69, 78);
    color: aliceblue;
    text-transform: uppercase;
}
.ability-row-grid .active {
    background-color: #c45500;
}

.ability-row-grid .active.flaw {
    background-color: #650d1b;
}

.ability-levels {
    display: grid;

    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.ability-level {
    margin-top: 20px;
    display: grid;

    grid-template-rows: 0.6rem 1.2rem;
    column-gap: 4px;
}

.ability-level label {
    font-size: 0.6rem;
    text-transform: uppercase;
}

.ability-level select {
    max-width: 60px;
}

.ability-level select {
    border-radius: 0;
    border: none;
    border-bottom: #0c1466 solid 1px;
}

.ability-level select:focus {
    outline: none;
    border-bottom-style: dashed;
}

.clear-level-boost {
    font-size: 0.6rem;

    max-width: 60px;

    justify-self: flex-start;
    align-self: flex-end;
}

@media (max-width: 900px) {
    .ability-rows {
        display: flex;
        flex-flow: column nowrap;
        gap: 4px;
    }

    .ability-level {
    }
}

/* TAB CONTENT */

.score-table {
    display: grid;

    grid-template-columns: 90px 40px 30px;
}

.score-table div {
    text-transform: capitalize;
    justify-self: flex-end;
    align-self: center;
    padding: 4px;
}

.score-table .error {
    background-color: rgba(101, 13, 27, 0.6);
    color: aliceblue;
    border: dashed 3px #650d1b;
    padding: 1px;
}
