/* reset css */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    /* font-size: 100%; */
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
}

/**
* Hidden fallback
*/

/**
* Styling navigation
*/

ol,
ul {
    list-style: none;
}

a {
    text-decoration: none;
}

header {
    margin-right: auto;
    margin-left: auto;
    max-width: 22.5rem;
    margin-top: 150px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.25);
}

body {
    font-family: 'Roboto', sans-serif;
    color: rgb(59, 69, 78);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* width: 100%; */
    /* height: 100vh; */
    /* overflow: hidden; */
}