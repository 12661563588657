.save-edit {
    display: flex;
    justify-content: flex-end;
}

.save-level table {
    width: 100%;
    max-width: 100%;
}
.save-level th {
    font-size: 0.8rem;
}

.save-level tr td {
    text-align: center;
}

.save-level input {
    width: 2rem;
    border-radius: 0;
    border: none;
    border-bottom: #0c1466 solid 1px;
    text-align: right;
}

.save-level input:focus {
    outline: none;
    border-bottom-style: dashed;
}

.save-level thead.save th {
    padding-top: 40px;
}
