.tab-panel-content h1 {
    grid-column: 1/-1;
    text-align: center;
}

.tab-heading {
    display: flex;
    justify-content: flex-start;
    align-content: flex-end;
    gap: 4px;
    margin-bottom: 8px;
}

.ability-tab {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    row-gap: 12px;
}

.ability-tab .badges {
    grid-column: 1 / -1;

    display: grid;
    grid-template-columns: 1fr 1fr;

    column-gap: 8px;
}

.ability-tab .badges .col {
    display: flex;
    flex-flow: column;
    gap: 12px;
}

.ability-tab h2 {
    font-size: 1.2rem;
}

.ability-tab .badge .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.ability-tab .row .label {
    grid-column: 1 / 3;
    grid-row: 1;
}

.ability-tab .row .rank-prof {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

.ability-tab .badge .row .final-value,
.ability-tab .badge .row .ac {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}

.ability-tab .badge .row .dc {
    grid-column: 2 / 3;
    grid-row: 2;
}

.ability-tab .badge .row sub {
    font-size: 0.6rem;
    font-weight: 600;
}


.ability-tab .badge .row .minor-label {
    font-size: 0.7rem;
}

.ability-tab .badge.combat .row .final-value,
.ability-tab .badge .row .final-value {
    display: flex;
    justify-content: space-between;
}