.hp-choice > div {
    padding: 4px 0px;

    display: grid;
    grid-template-rows: 0.6rem 2rem;
}

.hp-choice label {
    font-size: 0.6rem;
    text-transform: uppercase;
}

.hp-choice input,
.hp-choice select {
    border-radius: 0;
    border: none;
    border-bottom: #0c1466 solid 2px;
    max-width: 180px;
}

.hp-choice input:focus,
.hp-choice select:focus {
    outline: none;
    border-bottom-style: dashed;
}

.hp-choice input:disabled,
.hp-choice select:disabled {
    color: unset;
    background-color: unset;
}

.hp-formula {
    display: flex;
    margin-bottom: 40px;
    gap: 4px;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: 0.8rem;
}

.hp-formula sub {
    font-size: 0.55rem;
    white-space: pre;
}

.hp-bar {
    margin: 4px;
}

.hp-bar .hp-bar-label {
}

.hp-control {
    display: flex;
    margin: 4px;
    margin-top: 16px;
    gap: 4px;
    justify-content: center;
    align-items: stretch;
}

.hp-control button {
    flex: 1;
    min-width: 50px;
    font-size: larger;
}


.modal-modal.hp-lost-modal {
    left: 50%;
    right: unset;
    top: 50%;
    bottom: unset;
    transform: translate(-50%, -50%);

    width: auto;
    height: auto;
    min-width: 300px;
}


@media (max-width: 900px) {
    .hp-control button span {
        display: none;
    }
}

.hp-label span {
    padding-left: 16px;
}