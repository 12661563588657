/* SKILL RANK */

.skill-level p {
    font-size: 0.8rem;
    margin-bottom: 8px;
}

.skill-level ul {
    list-style: circle;
    margin-left: 20px;
    font-size: 0.7rem;
}

.extra-skill-control {
    display: flex;
    justify-content: space-around;
}

.extra-skill-control button {
    flex: 1;
    max-width: 100px;
}

.skill-level-row {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    width: 100%;
    max-width: 1000px;
}

.rank-cell {
    flex-basis: 140px;

    border: solid 3px #4e392ffc;
    box-shadow: 3px 3px #4e392ffc;
    max-width: 140px;
    min-width: 110px;

    background-color: white;
    padding: 4px;
    margin: 0px 3px 3px 0px;

    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    grid-template-rows: 0.7rem 1.2rem;
    row-gap: 3px;
}

.rank-cell label {
    font-size: 0.7rem;
}

.rank-cell input {
    place-self: stretch;
    width: calc(100% - 8px);
}

.rank-cell.class select {
    grid-column: 1 / -1;
}

.rank-cell.int {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
}

.rank-cell.inactive {
    background-color: rgba(59, 69, 78, 0.2);
    /* color: aliceblue */
}

.rank-cell.error {
    background-color: #650d1b;
    color: aliceblue;
}

.rank-cell.extra .split {
    display: flex;
    justify-content: space-between;
}

.rank-cell.extra button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
}
