
.ReactModal__Body--open,
.ReactModal__Body--open #root {
    overflow: hidden;
}


.modal-overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(59, 69, 78, 0.3);
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}


.modal-modal {
    position: absolute;
    top: 10vh;
    right: 1rem;
    left: 1rem;
    bottom: 0px;
    height: 80vh;
    width: calc(100vw - 2rem);
    background-color: white;
    overflow: hidden;

    padding: 2rem;

    display: flex;
    flex-flow: column nowrap;

    overflow-y: auto;
}

.modal-filtering {
    display: flex;
    flex-flow: column nowrap;
}

.modal-filtering input {
    border: none;
    border-bottom: solid 1px rgb(59, 69, 78);
}

.paged-items {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 1rem 0;
    border: 3px solid rgb(59, 69, 78);
    padding: 1rem;

    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
}

.item {
    border: 3px solid rgb(59, 69, 78);
}

.item-title {
    display: flex;
    justify-content: space-between;
    background-color: #c9c290;
}

.item-title h3 {
    font-size: large;
}

.item-traits {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    gap: 0;
}

.item-traits .trait {
    border-color: #d8c483;
    background-color: #522e2c;
    border-style: double;
    border-width: 2px;
    color: white;
    font-size: 1em;
    font-style: normal;
    font-weight: bold;
    padding-right: 5px;
    padding-left: 5px;
    text-align: left;
    text-indent: 0em;
    font-size: 0.8em;
    line-height: 1.4em;
    text-transform: uppercase;
}

.trait.t-common {
    background-color: #606c38;
}

.trait.t-uncommon {
    background-color: #c45500;
}

.trait.t-rare {
    background-color: #0c1466;
}

.trait.t-unique {
    background-color: #800080;
}

.pagination {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    right: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    line-height: 20px;
    font-size: x-small;
}

.pagination li {
    flex: 1;
    padding: 0;
    border: 1px solid rgb(59, 69, 78);
    text-align: center;

    height: 20px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.pagination li:first-child,
.pagination li:last-child {
    width: 80px;
}

.pagination li.active {
    font-weight: 800;
    height: 30px;
}

.page-link {
    position: relative;
    display: block;
    height: 100%;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}