/* RANK TABLE PRIME */
.rank-table {
    display: flex;
    flex-flow: column nowrap;
    max-width: 600px;
    row-gap: 4px;

    margin-bottom: 16px;
}

.rank-row {
    display: grid;
    grid-template-columns: 8fr 2fr 1fr 4fr 1fr 2fr 1fr 2fr;

    padding-bottom: 2px;
    border-bottom: rgba(12, 20, 102, 0.2) 1px solid;
    font-size: 0.9rem;

    align-items: flex-end;
}

.rank-row.header {
    font-size: 0.8rem;

    align-items: flex-end;
}

.rank-row > span {
    text-align: center;
}

.rank-row > span:nth-child(1) {
    text-align: left;
    font-weight: 700;
}

.rank-row > .modifier.ac {
    font-style: italic;
    opacity: 0.6;
}

.rank-prof {
    display: grid;
    grid-template-columns: repeat(4, 1rem);
    justify-content: center;
    position: relative;
}

.rank-prof > span {
    position: relative;
    width: 1rem;
    height: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.rank-prof label {
    font-size: 0.5rem;

    justify-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: aliceblue;
}

.rank-prof input {
    width: calc(1rem - 2px);
    height: calc(1rem - 2px);
    background-color: aliceblue;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #0c1466;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
}

.rank-prof input:checked {
    background-color: #c45500;
}

@media (max-width: 900px) {
    .rank-table {
        grid-template-columns: 1fr;
    }
}

.rank-table p {
    font-size: 0.8rem;
    font-style: italic;
}
