.worn {
    padding: 0 16px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 0.6rem 1.6rem;

    column-gap: 8px;

    justify-items: stretch;
    align-items: stretch;
}
.worn label {
    font-size: 0.6rem;
    text-transform: uppercase;
}

.worn input,
.worn select {
    border-radius: 0;
    border: none;
    border-bottom: #0c1466 solid 2px;
    width: 100%;
}

.worn input:focus,
.worn select:focus {
    outline: none;
    border-bottom-style: dashed;
}

#worn-name-label {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
}

#worn-type-label {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
}

#worn-name {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}

#worn-type {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}

#worn-item-label {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}

#worn-dex-label {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}

#worn-str-label {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}

#worn-item {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
}

#worn-dex {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
}

#worn-str {
    grid-column: 3 / 4;
    grid-row: 4 / 5;
}

#worn-apen-label {
    grid-column: 1 / 2;
    grid-row: 5 / 6;
}

#worn-spen-label {
    grid-column: 2/3;
    grid-row: 5 / 6;
}

#worn-apen {
    grid-column: 1 / 2;
    grid-row: 6 / 7;
}

#worn-spen {
    grid-column: 2/3;
    grid-row: 6 / 7;
}

#worn-value {
    grid-column: 3 / 4;
    grid-row: 6 / 7;
    justify-self: flex-end;
    align-self: flex-end;
    font-size: 0.8rem;
    font-weight: 600;
}

